<script setup lang="ts">
const { t } = useT();

const props = withDefaults(
	defineProps<{
		type: "popper" | "page";
	}>(),
	{ type: "popper" }
);

const { gameName, handleSearch, isExpanded, handleClickExpand } = useGameSearch();

const searchGroupRefs = ref<HTMLElement | null>(null);

const handleOutsideClick = (event: MouseEvent) => {
	if ((event.target as HTMLElement).closest(".box-search")) {
		return;
	}

	if (props.type === "popper" && isExpanded) {
		setTimeout(() => {
			handleClickExpand(false);
		}, 200);
	}
};

onClickOutside(searchGroupRefs, (event) => handleOutsideClick(event));
</script>
<template>
	<div class="box-search">
		<label ref="searchGroupRefs" :class="['search-group', { expanded: isExpanded }]">
			<span for="search" class="btn btn-show" @click="handleClickExpand(true)">
				<ASvg name="24/search" class="search-icon" />
				<AText :size="{ full: 12, md: 10 }" class="sub-txt text-neutral-100">{{ t("Search") }}</AText>
			</span>
			<MInput
				id="search"
				v-model="gameName"
				class="field"
				:placeholder="t('Game name')"
				@update:model-value="handleSearch"
			/>
			<span class="btn btn-hide" @click="handleClickExpand(false)">
				<ASvg name="24/close" class="close-icon" />
			</span>
		</label>

		<ClientOnly>
			<Teleport to="#nav-games">
				<div v-if="type === 'popper' && gameName.length" ref="searchGroupRefs" class="popper-result">
					<OSearchGamesResult />
				</div>
			</Teleport>
		</ClientOnly>
	</div>
</template>

<style lang="scss" scoped>
.popper-result {
	display: flex;
	width: 328px;
	height: auto;
	position: absolute;
	bottom: 1px;
	left: 0;
	transform: translateY(100%);
	border-radius: 8px;
	background: var(--gray-800);
	box-shadow: 0px 25px 50px -12px rgba(2, 6, 23, 0.25);
	padding: 16px;

	@include media-breakpoint-down(md) {
		left: 16px;
	}
}

.wrapper-popper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 296px;
}

.search-group {
	display: inline-flex;
	align-items: center;
	overflow: hidden;
	height: 56px;
	border-radius: 8px;
	width: 72px;
	transition: width 0.2s ease;
	border: 1px solid var(--gray-700);
	background: var(--gray-900);

	&.expanded {
		width: 240px;
		border: 1px solid var(--gray-800);
		background: var(--gray-800);

		.field,
		.btn-hide {
			opacity: 1;
			transition-delay: 0.3s;
		}

		.btn-show {
			pointer-events: none;

			.search-icon {
				color: #f9d01a;
				transform: translate(-10px, 10px);
			}
		}

		.sub-txt {
			opacity: 0;
			transition: opacity 0.3s ease;
			transition-delay: 0s;
		}
	}

	&.collapsed {
		width: 72px;
	}

	.field {
		--a-input-group-background: transparent;
		--a-input-padding: 8px 0;
		--a-input-color: var(--neutral-50);

		box-shadow: none;
		border: none;
		opacity: 0;
		transition: opacity 0.2s ease;
		transition-delay: 0s;
		margin-left: -26px;
	}

	.btn {
		display: inline-flex;
		align-items: center;
		height: 100%;
		font-size: 24px;
		flex-shrink: 0;
		cursor: pointer;

		&-show {
			width: 72px;
			justify-content: center;
			flex-direction: column;
			gap: 4px;
			position: relative;
			z-index: 1;

			.search-icon {
				color: var(--neutral-300);
				transform: translate(0, 0);
				transition: transform 0.3s ease;
			}
		}

		&-hide {
			width: 48px;
			padding-left: 8px;
			justify-content: flex-start;
			opacity: 0;
			transition: opacity 0.3s ease;
			transition-delay: 0s;
		}
	}

	.sub-txt {
		display: inline-flex;
		opacity: 1;
	}
}
</style>
